<template>
  <div class="flex flex-col w-3/5">
    <div class="flex flex-row mb-1">
      <h1 class="text-grey-dark-1 text-mdl mb-1 font-medium">
        Select products:
      </h1>
      <div class="ml-auto cursor-pointer flex items-center" @click="selectAll">
        <Checkbox :active="allSelected" data-testid="manager-checkbox" />
        <p class="pl-1">
          Select All
        </p>
      </div>
    </div>
    <div class="flex flex-col space-y-2" v-if="subDetails">
      <div
        v-for="product in details.selectedProducts"
        :key="product.id"
        @click="selectVal(product.id)"
        class="flex-row prodLabel cursor-pointer flex border border-grey-border rounded-md overflow-hidden"
        :class="{
          'pointer-events-none opacity-50':
            subDetails.subscriptionStatus === 1 && !product.canBeDisabled,
        }"
      >
        <div class="p-2 flex flex-row space-x-2 items-center">
          <Checkbox
            class="cursor-pointer"
            :active="product.isSelected"
            data-testid="manager-checkbox"
            :id="product.id"
          />
          <p class="text-grey-dark-2 text-mdh">
            {{ product.name }}
          </p>
        </div>
        <div class="ml-auto">
          <component :is="productLabel(product.backgroundColor)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import SupplyChain from "../../../../../assets/svgComponents/SupplyChain.vue";
import DataAnalytics from "../../../../../assets/svgComponents/DataAnalytics.vue";
import Leadership from "../../../../../assets/svgComponents/Leadership.vue";
import SoftSkills from "../../../../../assets/svgComponents/SoftSkills.vue";
import StrategicSourcing from "../../../../../assets/svgComponents/StrategicSourcing.vue";
import Sustainability from "../../../../../assets/svgComponents/Sustainability.vue";

export default {
  name: "SubscriptionProducts",
  components: {
    SupplyChain,
    DataAnalytics,
    Leadership,
    SoftSkills,
    StrategicSourcing,
    Sustainability,
  },
  computed: {
    ...mapState({
      details: (state) => state.subscriptions.form,
      subDetails: (state) => state.subscriptions.subscriptionDetails,
    }),
    allSelected() {
      return this.details.selectedProducts.every(
        (item) => item.isSelected === true
      );
    },
  },
  methods: {
    selectVal(prodId) {
      const foundItem = this.details.selectedProducts.find(
        (prod) => prod.id === prodId
      );
      foundItem.isSelected = !foundItem.isSelected;
    },
    selectAll() {
      if (this.allSelected) {
        this.details.selectedProducts.map((item) => {
          if (!(this.subDetails.subscriptionStatus === 1 && !item.canBeDisabled))
            item.isSelected = false;
        });
      } else {
        this.details.selectedProducts.map((item) => {
          if (!(this.subDetails.subscriptionStatus === 1 && !item.canBeDisabled))
            item.isSelected = true;
        });
      }
    },
    productLabel(color) {
      if (color === 'blue') return SupplyChain;
      if (color === 'purple') return DataAnalytics;
      if (color === 'turquoise') return Leadership;
      if (color === 'orange') return SoftSkills;
      if (color === 'green') return StrategicSourcing;
      if (color === 'lime') return Sustainability;
      return SupplyChain;
    },
  },
};
</script>
<style lang="scss" scoped>
.prodLabel {
  height: 51px;
}
</style>
