<template>
  <div class="flex flex-col">
    <h1 class="text-grey-dark-1 text-mdl mb-1 font-bold">
      Reminder Email
    </h1>
    <p class="px-2 text-grey-dark-1 text-mdh mb-3">
      Send an automatic email to remind customers that their subscription will
      be expiring soon.
    </p>
    <div class="flex flex-col px-2">
      <label class="text-grey-light text-sm mb-1">Days Before End Date</label>
      <div class="flex flex-row space-x-2">
        <div class="flex flex-row items-center">
          <Checkbox
            class="cursor-pointer"
            :active="isActive(90)"
            @click.native="selectVal(90)"
            data-testid="manager-checkbox"
          />
          <p class="pl-1 cursor-pointer" @click="selectVal(90)">
            90
          </p>
        </div>
        <div class="flex flex-row items-center">
          <Checkbox
            class="cursor-pointer"
            :active="isActive(60)"
            @click.native="selectVal(60)"
            data-testid="manager-checkbox"
          />
          <p class="pl-1 cursor-pointer" @click="selectVal(60)">
            60
          </p>
        </div>
        <div class="flex flex-row items-center">
          <Checkbox
            class="cursor-pointer"
            :active="isActive(30)"
            @click.native="selectVal(30)"
            data-testid="manager-checkbox"
          />
          <p class="pl-1 cursor-pointer" @click="selectVal(30)">
            30
          </p>
        </div>
        <div class="flex flex-row items-center">
          <Checkbox
            class="cursor-pointer"
            :active="isActive(14)"
            @click.native="selectVal(14)"
            data-testid="manager-checkbox"
          />
          <p class="pl-1 cursor-pointer" @click="selectVal(14)">
            14
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "SubscriptionEmail",
  computed: {
    ...mapState({
      details: (state) => state.subscriptions.form,
      subscription: (state) => state.subscriptions.subscriptionDetails,
    }),
    subscriptionStatus: function() {
      return this.subscription?.subscriptionStatus;
    },
  },
  methods: {
    ...mapActions({
      setFormValue: "subscriptions/setFormValue",
    }),
    selectVal(period) {
      var arr = [];
      const exists = this.details.reminders.find(
        (reminderDate) => reminderDate === period
      );
      if (exists) {
        arr = this.details.reminders.filter((date) => date !== period);
      } else {
        arr = [...this.details.reminders, period];
      }
      this.setFormValue({ key: "reminders", value: arr });
    },
    isActive(period) {
      return this.subscriptionStatus === 0
        ? true
        : !!this.details.reminders.find(
            (reminderDate) => reminderDate === period
          );
    },
  },
  mounted() {
    const emailDates = [14, 30, 60, 90];
    if (this.subscriptionStatus === 0) {
      this.details.reminders = [];
      emailDates.forEach((date) => this.selectVal(date));
    }
  },
};
</script>
